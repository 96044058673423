import { computed, ref } from 'vue';
import { TrackingInput } from '@/models/filter-tracking/TrackingInput';
import { ControlEvent } from '@/models/filter-tracking/ControlEvent';
import { Action } from '@studyportals/datalake-event-tracker';
import { FilterKey } from '@/models/FilterKey';
import TuitionFeeFilter from './TuitionFeeFilter.class';

export class TuitionFeeFilterTracking {
	private readonly filter: TuitionFeeFilter;

	public constructor(filter: TuitionFeeFilter) {
		this.filter = filter;
	}

	private trackingdata = computed(() => {
		const valueFrom = this.filter.fromSelected.value;
		const valueTo = this.filter.toSelected.value === this.filter.maxValue.value ? 'null' : this.filter.toSelected.value.toString();
		return `[${valueFrom},${valueTo}]`;
	});

	public trackingDataForFromInput = computed(() => {
		return new TrackingInput(this.filter.fromInputRef, this.trackingdata, ref(Action.CHANGE), ControlEvent.CHANGE, FilterKey.TUITION_FEE);
	});

	public trackingDataForToInput = computed(() => {
		return new TrackingInput(this.filter.toInputRef, this.trackingdata, ref(Action.CHANGE), ControlEvent.CHANGE, FilterKey.TUITION_FEE);
	});

	public trackingDataForFromSlider = computed(() => {
		return new TrackingInput(
			this.filter.fromSliderRef,
			this.trackingdata,
			ref(Action.SLIDER_CHANGE),
			ControlEvent.CHANGE,
			FilterKey.TUITION_FEE
		);
	});

	public trackingDataForToSlider = computed(() => {
		return new TrackingInput(
			this.filter.toSliderRef,
			this.trackingdata,
			ref(Action.SLIDER_CHANGE),
			ControlEvent.CHANGE,
			FilterKey.TUITION_FEE
		);
	});
}
