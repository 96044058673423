import { defineComponent, Component, onUnmounted } from 'vue';
import { IFilterProvider } from '@/interfaces/filter-providers/IFilterProvider';
import { FilterProviderKey } from '@/platform/FilterProviderKey';
import { InjectExternal } from '../../../platform/InjectExternal';
import ContinentFilter from './ContinentFilter.class';
import Checkbox from '../../checkbox/Checkbox.vue';

export default defineComponent({
	components: {
		Checkbox: Checkbox as Component,
	},
	setup: () => {
		const filterProvider = InjectExternal<IFilterProvider>(FilterProviderKey.FILTER_PROVIDER);
		const component = new ContinentFilter(filterProvider);
		onUnmounted(() => component.dispose());

		return {
			filterOptions: component.continentFilterOptions,
			key: component.key,
			filterProvider,
		};
	},
});
