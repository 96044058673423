export class TuitionFeeInterval {
	public readonly isDefault: boolean;
	public readonly min: number;
	public readonly max: number;
	public readonly hasMinOnly: boolean;
	public readonly hasMaxOnly: boolean;

	private readonly defaultMin = 0;
	private readonly defaultMax = 0;
	private readonly isDefaultMin: boolean;
	private readonly isDefaultMax: boolean;

	public constructor(interval: string) {
		if (!this.exactMatch(interval)) {
			throw new Error('Invalid interval');
		}

		const parsed = JSON.parse(interval) as string[];
		this.min = +parsed[0];
		this.max = +parsed[1];
		this.isDefaultMin = this.min <= this.defaultMin;
		this.isDefaultMax = this.max <= this.defaultMax;
		this.isDefault = this.isDefaultMin && this.isDefaultMax;
		this.hasMinOnly = !this.isDefaultMin && this.isDefaultMax;
		this.hasMaxOnly = !this.isDefaultMax && this.isDefaultMin;
	}

	private exactMatch(interval: string): boolean {
		const regex = /[-?[0-9]+,-?[0-9]+]/g;
		const match = interval.match(regex);
		return match !== null && match[0] === interval;
	}
}
