import { FilterKey } from './models/FilterKey';
import { EventAggregationService } from './platform/EventAggregationService';
import { EventAggregationServiceSubscription } from './platform/EventAggregationServiceSubscription';
import { ISubscriber } from '@studyportals/event-aggregation-service-interface';
import { FilterChangeInitiatedEvent } from './events/FilterChangeInitiatedEvent';
import { FilterChangeCompletedEvent } from './events/FilterChangeCompletedEvent';
import { BusyIndicator } from '@/BusyIndicator';

export abstract class FilterBase implements ISubscriber<FilterChangeInitiatedEvent>, ISubscriber<FilterChangeCompletedEvent> {
	protected readonly eventAggregationService: EventAggregationService;

	private readonly subscriptionOfChangeEvents: EventAggregationServiceSubscription;

	public readonly key: FilterKey;
	public readonly busyIndicator: BusyIndicator;

	public constructor(filterKey: FilterKey, eventAggregationService: EventAggregationService | undefined) {
		this.busyIndicator = new BusyIndicator();

		this.key = filterKey;
		this.eventAggregationService = eventAggregationService ?? EventAggregationService.instance;

		this.subscriptionOfChangeEvents = this.eventAggregationService
			.subscribe(this)
			.to(FilterChangeInitiatedEvent.EventType)
			.to(FilterChangeCompletedEvent.EventType);
	}

	public notify(event: FilterChangeInitiatedEvent | FilterChangeCompletedEvent): void {
		if (FilterChangeInitiatedEvent.is(event) && event.subjects.includes(this.key)) {
			this.busyIndicator.reportChange();
		}

		if (FilterChangeCompletedEvent.is(event) && event.subjects.includes(this.key)) {
			this.busyIndicator.reportChangeCompleted();
		}
	}

	public dispose(): void {
		this.subscriptionOfChangeEvents.dispose();
	}
}
