import { ref, watch, Ref } from 'vue';
import { AutocompleteSuggestion } from '@/infrastructure/AutocompleteSuggestion';
import { PerformDebounce } from '@/platform/PerformDebounce';
import { ControlEvent } from '@/models/filter-tracking/ControlEvent';
import { FilterKey } from '@/models/FilterKey';

export class DropdownFilter {
	public inputRef = ref<HTMLInputElement | null>(null);
	public readonly input = ref('');
	public readonly suggestions = ref<readonly AutocompleteSuggestion[]>([]);
	public readonly refOfTrackingProxy: Ref<Element | null> = ref(null);
	public readonly filterKey: FilterKey;
	public readonly selectionId = ref('');

	private readonly suggest: (query: string) => Promise<readonly AutocompleteSuggestion[]>;
	private readonly processSelection: (value: string) => Promise<void>;
	private readonly inputDisplay: Ref<string>;
	private readonly debounceInput: ReturnType<PerformDebounce['for']>;
	private lastValidSelectionOption: Ref<string>;

	public constructor(
		suggest: (query: string) => Promise<readonly AutocompleteSuggestion[]>,
		processSelection: (value: string) => Promise<void>,
		filterKey: FilterKey,
		lastValidSelectionOption: Ref<string>,
		inputDisplay: Ref<string>
	) {
		this.suggest = suggest;
		this.processSelection = processSelection;
		this.lastValidSelectionOption = lastValidSelectionOption;
		this.inputDisplay = inputDisplay;
		this.filterKey = filterKey;

		this.debounceInput = new PerformDebounce().for(() => void this.onInputChanged(this.input.value), 500);
	}

	public onMounted(): void {
		watch(this.inputDisplay, (value) => this.updateInput(value));
	}

	public onInput(event: Event): void {
		const target = event.target as HTMLInputElement;
		this.input.value = target.value;

		this.debounceInput();
	}

	public onBlur(): void {
		if (this.lastValidSelectionOption.value !== '' && this.suggestions.value.length === 0) {
			this.input.value = this.lastValidSelectionOption.value;
		}
	}

	public onClearInputClick(): void {
		this.updateInputForDisplay('');
		this.inputRef.value?.focus();
	}

	private async onInputChanged(query: string): Promise<void> {
		this.suggestions.value = await this.suggest(query);
	}

	public onSuggestionChosen(suggestion: AutocompleteSuggestion): void {
		this.selectionId.value = suggestion.id.toString();

		if (this.refOfTrackingProxy.value) {
			this.refOfTrackingProxy.value.dispatchEvent(new Event(ControlEvent.CHANGE));
		}

		this.updateInputForDisplay(suggestion.name);

		void this.processSelection(suggestion.id.toString());
	}

	private updateInput(value: string): void {
		this.updateInputForDisplay(value);
		this.lastValidSelectionOption.value = value;
	}

	private updateInputForDisplay(value: string): void {
		this.input.value = value;
		this.suggestions.value = [];
	}
}
