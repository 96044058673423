import { FilterKey } from '@/models/FilterKey';
import { FilterOptionInformation } from '@/models/FilterOptionInformation';
import { IFilterProvider } from '@/interfaces/filter-providers/IFilterProvider';
import { FilterBase } from '@/FilterBase.class';
import { EventAggregationService } from '@/platform/EventAggregationService';

export default class ContinentFilter extends FilterBase {
	public continentFilterOptions: readonly FilterOptionInformation[];

	public constructor(private filterProvider: IFilterProvider, eventAggregationService?: EventAggregationService) {
		super(FilterKey.CONTINENT, eventAggregationService);

		this.continentFilterOptions = this.filterProvider.getFilterOptionInformation(this.key);
	}
}
