<template>
	<section class="LocationFilterWrapper" :class="{ 'is-collapsed': collapsed }">
		<header class="RefineSectionHeading" @click="toggleFilter()">
			<i class="lnr-map-marker FilterIcon"></i>
			<div class="Title">Location</div>
			<span v-if="countryFilterHintCount > 0" class="Badge">
				{{ countryFilterHintCount }}
			</span>
			<span v-if="continentFilterHintCount > 0" class="Badge">
				{{ continentFilterHintCount }}
			</span>
			<i class="ToggleIcon" :class="[collapsed ? 'lnr-chevron-down' : 'lnr-chevron-up']"></i>
		</header>

		<LocationSkeletonLoader v-if="isBusy" />
		<div class="RefineContentOptionsList" v-else>
			<div v-if="includeContinents" class="LocationSwitcher">
				<button
					type="button"
					@click="toggleCountryFilter"
					class="CountrySwitcher"
					:class="{ 'is-active': activeLocationFilterType === LocationFilterType.COUNTRY }"
				>
					Country
				</button>
				<i class="lnr-arrows-switch SwitcherIcon" @click="switchTabs"></i>
				<button
					type="button"
					@click="toggleContinentFilter"
					class="ContinentSwitcher"
					:class="{ 'is-active': activeLocationFilterType === LocationFilterType.CONTINENT }"
				>
					Continent
				</button>
			</div>

			<component :is="activeLocationFilterType"></component>
		</div>
	</section>
</template>

<script lang="ts" src="./LocationFilter.ts"></script>
<style lang="scss" scoped src="./LocationFilter.scss"></style>
