import { IEvent } from '@studyportals/event-aggregation-service-interface';
import { FilterKey } from '../models/FilterKey';

export class FilterChangeCompletedEvent implements IEvent {
	public static readonly EventType = 'SearchFilters:FilterChangeCompletedEvent';

	public readonly timestamp = new Date();
	public readonly eventType = FilterChangeCompletedEvent.EventType;
	public readonly subjects: readonly FilterKey[];

	public constructor(subjects: readonly FilterKey[]) {
		this.subjects = subjects;
	}

	public static is(event: IEvent): event is FilterChangeCompletedEvent {
		return event.eventType === FilterChangeCompletedEvent.EventType;
	}
}
