import { computed, defineComponent, ref, toRef, watch, Ref, PropType, Component } from 'vue';
import { Tag } from '../../../models/tags/Tag';
import { TrackingInput } from '@/models/filter-tracking/TrackingInput';
import Tracking from '../../tracking/Tracking.vue';
import { ControlEvent } from '@/models/filter-tracking/ControlEvent';
import { Action } from '@studyportals/datalake-event-tracker';
import { ITrackingInput } from '@/presentation/tracking/ITrackingInput';

export default defineComponent({
	components: {
		Tracking: Tracking as Component,
	},
	props: {
		tag: {
			type: Object as PropType<Ref<Tag>>,
			required: true,
		},
	},
	setup: (props) => {
		const tag = toRef(props, 'tag');

		const tagRef = ref<HTMLElement | null>(null);

		const tagValues = computed(() => {
			return tag.value.values.join(',');
		});

		const trackingData: Ref<ITrackingInput | null> = ref(null);
		const maintainTrackingInput = (): void => {
			trackingData.value = tag.value.disabled
				? null
				: new TrackingInput(tagRef, tagValues, ref(Action.CLEAR), ControlEvent.CLICK, tag.value.key);
		};

		watch(tag, () => maintainTrackingInput());
		maintainTrackingInput();

		return {
			tag,
			tagRef,
			trackingData,
		};
	},
});
