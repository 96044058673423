import { defineComponent, inject, Component } from 'vue';
import { CheckboxOptions } from '@/interfaces/ICheckboxOptions';
import { CountryFilterContext } from '@/presentation/location/country/CountryFilterContext';
import { FilterKey } from '../../../models/FilterKey';
import AreaCheckbox from './AreaCheckbox.class';
import Tracking from '@/presentation/tracking/Tracking.vue';

export default defineComponent({
	components: {
		Tracking: Tracking as Component,
	},
	props: {
		optionName: String,
		optionValue: String,
		filterKey: String,
	},
	emits: ['areaFilterChange'],
	setup: (props, context) => {
		const countryFilterContext: CountryFilterContext | undefined = inject(CountryFilterContext.key);
		if (countryFilterContext == null) {
			throw new Error(`"${CountryFilterContext.key}" class was not provided for AreaCheckbox`);
		}

		const emitAreaFilterChange = (areaId: string): void => context.emit('areaFilterChange', areaId);
		const checkboxOptions = props as CheckboxOptions;

		const component = new AreaCheckbox(
			checkboxOptions.optionName,
			checkboxOptions.optionValue,
			props.filterKey as FilterKey,
			countryFilterContext.filterProvider,
			emitAreaFilterChange
		);

		return {
			facet: component.facet,
			selectedOptions: component.selectedOptions,
			displayFacets: component.displayFacets,
			checkboxInputRef: component.checkboxInputRef,
			trackingData: component.trackingData,
			updateAreaSelection: component.updateAreaSelection.bind(component),
		};
	},
});
