import { IFilterPayload } from '@/interfaces/IFilterPayload';
import { IEvent } from '@studyportals/event-aggregation-service-interface';

export class ClearFiltersRequestedEvent implements IEvent {
	public static readonly EventType = 'ClearFiltersRequestedEvent';

	public readonly timestamp = new Date();
	public readonly eventType = ClearFiltersRequestedEvent.EventType;
	public readonly filters: IFilterPayload[] = [];

	public constructor(filters: IFilterPayload[]) {
		this.filters = filters;
	}

	public static is(event: IEvent): event is ClearFiltersRequestedEvent {
		return event.eventType === ClearFiltersRequestedEvent.EventType;
	}
}
