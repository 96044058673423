import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-85e5913c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "DisciplineName" }
const _hoisted_2 = {
  key: 1,
  class: "Facet"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tracking = _resolveComponent("Tracking")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      ref: "disciplineOptionRef",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectOption(_ctx.discipline))),
      class: _normalizeClass({
			'is-active': _ctx.discipline.optionValue === _ctx.selectedDiscipline,
			MainDiscipline: _ctx.discipline.parentIdentity === undefined,
			SubDiscipline: _ctx.discipline.parentIdentity !== undefined,
		})
    }, [
      (_ctx.discipline.parentIdentity === undefined)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            class: _normalizeClass(["DisciplineIcon", _ctx.getIconOf(_ctx.discipline)])
          }, null, 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.discipline.optionName), 1 /* TEXT */),
      (_ctx.displayFacets)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.facet(_ctx.discipline.optionValue)), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */),
    (_ctx.trackingData)
      ? (_openBlock(), _createBlock(_component_Tracking, {
          key: 0,
          data: _ctx.trackingData
        }, null, 8 /* PROPS */, ["data"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}