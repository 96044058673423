import { FilterOptionInformation } from './FilterOptionInformation';

export class DisciplineFilterOptionInformation extends FilterOptionInformation {
	public constructor(
		public readonly optionName: string,
		public readonly optionValue: string,
		public readonly optionPath: string,
		public readonly optionTitle: string,
		public readonly parentIdentity: string | undefined,
		public readonly icon: string | undefined,
		public readonly synonym: string
	) {
		super(optionName, optionValue, optionPath, optionTitle);
	}

	public isMainDiscipline(): boolean {
		return this.parentIdentity === undefined;
	}
}
