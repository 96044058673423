<template>
	<div class="CountryFilterWrapper">
		<ul class="RefineContentOptionsList">
			<li v-for="item in countryFilterOptions" :key="item.optionValue">
				<CountryCheckbox
					:optionName="item.optionName"
					:optionValue="item.optionValue"
					:hasAreas="item.hasAreas"
					v-bind:class="{ Indented: !item.hasAreas }"
				/>

				<Areas v-if="item.hasAreas" :countryId="item.optionValue" @areaFilterChange="processAreaFilterChange" />
			</li>
		</ul>
		<button type="button" class="AddCountry" v-if="!showMultiselect" @click="displayMultiselect"><i class="lnr-plus"></i> Add</button>

		<TrackedMultiSelect
			v-if="showMultiselect"
			:options="transformedCountryFormat"
			label=""
			placeholder="Add country"
			:searchable="true"
			:filterKey="key"
			@updated="onSelect"
		/>
	</div>
</template>

<script lang="ts" src="./CountryFilter.ts"></script>
<style lang="scss" scoped src="./CountryFilter.scss"></style>
