import { FilterKey } from '@/models/FilterKey';

export class FilterTitle {
	public static readonly instance = new FilterTitle();

	private titles = new Map<FilterKey, string>([
		[FilterKey.ATTENDANCE, 'Attendance'],
		[FilterKey.ORGANISATIONS, 'Organisations'],
		[FilterKey.DISCIPLINES, 'Disciplines'],
		[FilterKey.EDUCATION_LEVEL, 'Education Levels'],
		[FilterKey.CONTINENT, 'Continent'],
		[FilterKey.COUNTRY, 'Countries'],
		[FilterKey.AREA, 'States'],
		[FilterKey.TUITION_FEE, 'Tuition Fees'],
		[FilterKey.DURATION, 'Duration'],
		[FilterKey.DELIVERY_METHOD, 'Delivery Methods'],
		[FilterKey.DEGREE_TYPE, 'Degree Types'],
		[FilterKey.EDUCATIONAL_FORM, 'Educational Forms'],
		[FilterKey.SPECIAL_PROGRAMMES, 'Special Programmes'],
		[FilterKey.ORGANISATIONS, 'Universities'],
	]);

	public getFor(key: FilterKey): string {
		return this.titles.get(key) ?? '';
	}
}
