import { computed, ref } from 'vue';
import { IFilterProvider } from '@/interfaces/filter-providers/IFilterProvider';
import { FilterKey } from '@/models/FilterKey';
import { TrackingInput } from '@/models/filter-tracking/TrackingInput';
import { ControlEvent } from '@/models/filter-tracking/ControlEvent';
import { Action } from '@studyportals/datalake-event-tracker';

export default class Checkbox {
	public checkboxInputRef = ref<HTMLInputElement | null>(null);
	private trackingAction = ref(Action.UNCHECK);

	public constructor(
		public readonly optionName: string,
		public readonly optionValue: string,
		public readonly filterKey: FilterKey,
		public readonly filterProvider: IFilterProvider
	) {}

	public facet = computed((): number | string => {
		const facet = this.filterProvider.getFacet(this.filterKey, this.optionValue);
		return facet ?? 0;
	});

	public displayFacets = computed((): boolean => {
		return this.filterProvider.displayFacets;
	});

	public selectedOptions = computed((): readonly string[] => {
		return this.filterProvider.getFilterSelection(this.filterKey);
	});

	public trackingData = computed(() => {
		const result = new TrackingInput(this.checkboxInputRef, ref(this.optionValue), this.trackingAction, ControlEvent.CLICK, this.filterKey);

		return result;
	});

	public updateTackingAction(): void {
		const checked = this.checkboxInputRef.value?.checked ?? false;

		if (checked) {
			this.trackingAction.value = Action.CHECK;
		} else {
			this.trackingAction.value = Action.UNCHECK;
		}
	}

	public async updateSelection(): Promise<void> {
		this.updateTackingAction();
		await this.filterProvider.processFilterSelection({
			key: this.filterKey,
			value: this.optionValue,
		});
	}
}
