import { computed, ref } from 'vue';
import { FilterKey, FilterKeysOfLocation } from '@/models/FilterKey';
import { IFilterProvider } from '@/interfaces/filter-providers/IFilterProvider';
import { LocationFilterType } from '@/models/LocationFilterType';
import { ILocationFilterProvider } from '@/interfaces/filter-providers/ILocationFilterProvider';
import { ISubscriber } from '@studyportals/event-aggregation-service-interface';
import { ClearLocationFilterEvent } from './events/ClearLocationFilterEvent';
import { EventAggregationService } from '@/platform/EventAggregationService';
import { EventAggregationServiceSubscription } from '@/platform/EventAggregationServiceSubscription';
import { FilterChangeInitiatedEvent } from '@/events/FilterChangeInitiatedEvent';
import { FilterChangeCompletedEvent } from '@/events/FilterChangeCompletedEvent';
import { BusyIndicator } from '@/BusyIndicator';

export default class LocationFilter
	implements ISubscriber<ClearLocationFilterEvent>, ISubscriber<FilterChangeInitiatedEvent>, ISubscriber<FilterChangeCompletedEvent>
{
	private readonly eventAggregationServiceSubscription: EventAggregationServiceSubscription;

	public readonly busyIndicator: BusyIndicator;
	public readonly selectedLocationFilterType = ref<LocationFilterType | null>(null);

	public constructor(private filterProvider: IFilterProvider, public readonly locationFilterProvider: ILocationFilterProvider) {
		this.eventAggregationServiceSubscription = EventAggregationService.instance
			.subscribe(this)
			.to(ClearLocationFilterEvent.EventType)
			.to(FilterChangeInitiatedEvent.EventType)
			.to(FilterChangeCompletedEvent.EventType);

		this.busyIndicator = new BusyIndicator();
	}

	public readonly activeLocationFilterType = computed(() => {
		if (this.selectedLocationFilterType.value !== null) {
			return this.selectedLocationFilterType.value;
		}

		return this.areContinentsSelected() ? LocationFilterType.CONTINENT : LocationFilterType.COUNTRY;
	});

	private areContinentsSelected(): boolean {
		return this.filterProvider.getFilterSelection(FilterKey.CONTINENT).length > 0;
	}

	public collapsed = computed((): boolean => {
		return this.filterProvider.isFilterCollapsed(FilterKey.COUNTRY);
	});

	public countryFilterHintCount = computed(() => {
		return this.filterProvider.getFilterSelection(FilterKey.COUNTRY).length;
	});

	public continentFilterHintCount = computed(() => {
		return this.filterProvider.getFilterSelection(FilterKey.CONTINENT).length;
	});

	public async notify(event: ClearLocationFilterEvent | FilterChangeInitiatedEvent | FilterChangeCompletedEvent): Promise<void> {
		if (FilterChangeInitiatedEvent.is(event) && event.subjects.some((s) => FilterKeysOfLocation.includes(s))) {
			this.busyIndicator.reportChange();
			this.displayCorrectLocationTab(event.subjects);
		}

		if (FilterChangeCompletedEvent.is(event) && event.subjects.some((s) => FilterKeysOfLocation.includes(s))) {
			this.busyIndicator.reportChangeCompleted();
		}

		if (ClearLocationFilterEvent.is(event)) {
			await this.filterProvider.clearFilters([FilterKey.COUNTRY, FilterKey.AREA, FilterKey.CONTINENT]);
		}
	}

	public displayCorrectLocationTab(subjects: readonly FilterKey[]): void {
		const subjectsContainCountry = subjects.includes(FilterKey.COUNTRY);
		if (subjectsContainCountry) {
			this.toggleCountryFilter();
			return;
		}

		const subjectsContainContinent = subjects.includes(FilterKey.CONTINENT);
		if (subjectsContainContinent) {
			this.toggleContinentFilter();
		}
	}

	public switchTabs(): void {
		if (this.activeLocationFilterType.value === LocationFilterType.COUNTRY) {
			this.switchToContinentFilter();
		} else {
			this.switchToCountryFilter();
		}
	}

	private async switchToCountryFilter(): Promise<void> {
		this.selectedLocationFilterType.value = LocationFilterType.COUNTRY;

		const selection = this.filterProvider.getFilterSelection(FilterKey.CONTINENT);
		if (selection.length > 0) {
			await this.filterProvider.clearFilter(FilterKey.CONTINENT);
		}
	}

	private async switchToContinentFilter(): Promise<void> {
		this.selectedLocationFilterType.value = LocationFilterType.CONTINENT;

		const countrySelection = this.filterProvider.getFilterSelection(FilterKey.COUNTRY);
		const areaSelection = this.filterProvider.getFilterSelection(FilterKey.AREA);
		if (countrySelection.length > 0 || areaSelection.length > 0) {
			await this.filterProvider.clearFilters([FilterKey.COUNTRY, FilterKey.AREA]);
		}
	}

	public async toggleContinentFilter(): Promise<void> {
		if (this.activeLocationFilterType.value === LocationFilterType.COUNTRY) {
			await this.switchToContinentFilter();
		}
	}

	public async toggleCountryFilter(): Promise<void> {
		if (this.activeLocationFilterType.value === LocationFilterType.CONTINENT) {
			await this.switchToCountryFilter();
		}
	}

	public toggleFilter(): void {
		this.filterProvider.toggleFilterExpandability(FilterKey.COUNTRY);
	}

	public dispose(): void {
		this.eventAggregationServiceSubscription.dispose();
	}
}
