import { IEvent } from '@studyportals/event-aggregation-service-interface';
import { FilterKey } from '../models/FilterKey';

export class FilterChangeInitiatedEvent implements IEvent {
	public static readonly EventType = 'FilterChangeInitiatedEvent';

	public readonly timestamp = new Date();
	public readonly eventType = FilterChangeInitiatedEvent.EventType;
	public readonly subjects: readonly FilterKey[];

	public constructor(subjects: readonly FilterKey[]) {
		this.subjects = subjects;
	}

	public static is(event: IEvent): event is FilterChangeInitiatedEvent {
		return event.eventType === FilterChangeInitiatedEvent.EventType;
	}
}
