import { defineComponent, Component, onUnmounted } from 'vue';

import { IFilterProvider } from '@/interfaces/filter-providers/IFilterProvider';
import { FilterProviderKey } from '@/platform/FilterProviderKey';
import { InjectExternal } from '../../platform/InjectExternal';
import FormatFilter from './FormatFilter.class';
import Checkbox from '../checkbox/Checkbox.vue';

export default defineComponent({
	components: {
		Checkbox: Checkbox as Component,
	},
	setup: () => {
		const filterProvider = InjectExternal<IFilterProvider>(FilterProviderKey.FILTER_PROVIDER);
		const component = new FormatFilter(filterProvider);
		onUnmounted(component.dispose.bind(component));

		return {
			key: component.key,
			filterOptions: component.filterOptions,
			collapsed: component.collapsed,
			filterHintCount: component.filterHintCount,
			toggleFilter: component.toggleFilter.bind(component),
			filterProvider,
		};
	},
});
