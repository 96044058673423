import { defineComponent, Component } from 'vue';

import DisciplineFilter from './DisciplineFilter.class';
import { IFilterProvider } from '@/interfaces/filter-providers/IFilterProvider';
import { FilterProviderKey } from '@/platform/FilterProviderKey';
import { InjectExternal } from '../../platform/InjectExternal';
import Tracking from '../tracking/Tracking.vue';
import DisciplineOption from './discipline-option.ts/DisciplineOption.vue';
import { DisciplineFilterTracking } from './DisciplineFilter.tracking';

export default defineComponent({
	components: {
		Tracking: Tracking as Component,
		DisciplineOption: DisciplineOption as Component,
	},
	setup: () => {
		const filterProvider = InjectExternal<IFilterProvider>(FilterProviderKey.FILTER_PROVIDER);
		const component = new DisciplineFilter(filterProvider);
		const tracking = new DisciplineFilterTracking(component);

		return {
			key: component.key,
			filterOptions: component.filterOptions,
			collapsed: component.collapsed,
			filterHintCount: component.filterHintCount,
			selectedDiscipline: component.selectedDiscipline,
			disciplineFilterOptions: component.disciplineFilterOptions,
			displayFacets: component.displayFacets,
			isDisciplineSelected: component.isDisciplineSelected,
			allDisciplinesButtonRef: component.allDisciplinesButtonRef,
			allDisciplinesTrackingData: tracking.allDisciplinesTrackingData,
			toggleFilter: component.toggleFilter.bind(component),
			removeSelection: component.removeSelection.bind(component),
			showDiscipline: component.disciplineVisibility.showDiscipline.bind(component.disciplineVisibility),
		};
	},
});
