<template>
	<section class="UniversityFilterWrapper" :class="{ 'is-collapsed': collapsed }">
		<header class="RefineSectionHeading" @click="toggleFilter()">
			<i class="lnr-sp-logo FilterIcon"></i>
			<div class="Title">University</div>
			<span v-if="filterHintCount > 0" class="Badge">
				{{ filterHintCount }}
			</span>
			<i class="ToggleIcon" :class="[collapsed ? 'lnr-chevron-down' : 'lnr-chevron-up']"></i>
		</header>

		<div class="RefineContentOptionsList">
			<label class="UniversityFilterLabel">Search by university</label>
			<DropdownFilter
				:suggest="suggest"
				:processSelection="processSelection"
				:lastValidSelectionOption="lastValidSelectionOption"
				:inputDisplay="selectionName"
				:filterKey="key"
				placeholder="University name"
			/>
		</div>
	</section>
</template>

<script lang="ts" src="./UniversityFilter.ts"></script>
<style lang="scss" src="./UniversityFilter.scss" scoped></style>
