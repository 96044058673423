import { IFilterProvider } from '@/interfaces/filter-providers/IFilterProvider';
import { FilterKey } from '../FilterKey';
import { FilterTitle } from '@/presentation/FilterTitle';
import { EventAggregationService } from '@/platform/EventAggregationService';
import { ClearFiltersRequestedEvent } from '@/events/ClearFiltersRequestedEvent';
import { IEventAggregationService } from '@studyportals/event-aggregation-service-interface';

export class Tag {
	public readonly key: FilterKey;
	public readonly values: readonly string[];
	public readonly required: boolean;
	public readonly disabled: boolean;
	public readonly displayName: string;
	public readonly count: number;
	public readonly hasMultipleValues: boolean;
	public readonly id: string;

	protected readonly filterProvider: IFilterProvider;

	private readonly eventAggregationService: IEventAggregationService;

	public constructor(
		key: FilterKey,
		values: readonly string[],
		required: boolean,
		disabled: boolean,
		filterProvider: IFilterProvider,
		eventAggregationService?: IEventAggregationService
	) {
		this.key = key;
		this.values = values;
		this.required = required;
		this.disabled = disabled;
		this.filterProvider = filterProvider;
		this.displayName = this.constructDisplayName();
		this.count = this.countFilters();
		this.hasMultipleValues = this.values.length > 1;
		this.eventAggregationService = eventAggregationService ?? EventAggregationService.instance;
		this.id = `${key}-${values.join('-')}`;
	}

	public async clear(): Promise<void> {
		if (this.disabled) {
			return;
		}

		await this.clearFilter();
	}

	protected clearFilter(): Promise<void> {
		const payload = this.values.map((value) => {
			return { key: this.key, value };
		});

		this.eventAggregationService.publishTo(ClearFiltersRequestedEvent.EventType, new ClearFiltersRequestedEvent(payload));

		return Promise.resolve();
	}

	protected getDisplayNameforMultipleValues(): string {
		return FilterTitle.instance.getFor(this.key);
	}

	protected getDisplayNameForSingleValue(value: string): string {
		const option = this.filterProvider.getFilterOptionInformationById(this.key, value);
		return option?.optionShortTitle ?? option?.optionName ?? '';
	}

	private countFilters(): number {
		if (this.disabled || this.required) {
			return 0;
		}
		return this.values.length;
	}

	private constructDisplayName(): string {
		if (this.values.length === 1) {
			return this.getDisplayNameForSingleValue(this.values[0]);
		}
		return this.getDisplayNameforMultipleValues();
	}
}
