import { OnSelectEvent } from '@/interfaces/IOnSelectEvent';
import { IFilterProvider } from '@/interfaces/filter-providers/IFilterProvider';
import { FilterKey } from '@/models/FilterKey';
import { ref, Ref } from 'vue';

export class CountryDropdown {
	public countryMultiselectRef = ref<HTMLElement | null>(null);
	public show: Ref<boolean>;

	private filterProvider: IFilterProvider;

	public constructor(filterProvider: IFilterProvider) {
		this.filterProvider = filterProvider;
		this.show = ref(false);
	}

	public async onSelect(event: OnSelectEvent): Promise<void> {
		if (!event || !event.value) {
			return;
		}

		this.show.value = false;

		await this.filterProvider.processFilterSelection({
			key: FilterKey.COUNTRY,
			value: event.value,
		});
	}

	public display(): void {
		this.show.value = true;
	}
}
