import { Ref } from 'vue';

export class DisciplineVisibility {
	private readonly selectedDiscipline: Ref<string | undefined>;
	private readonly selectedParentIdentity: Ref<string | undefined>;

	public constructor(selectedDiscipline: Ref<string | undefined>, selectedParentIdentity: Ref<string | undefined>) {
		this.selectedDiscipline = selectedDiscipline;
		this.selectedParentIdentity = selectedParentIdentity;
	}

	public showDiscipline(id: string, parentId: string | undefined): boolean {
		if (parentId === undefined) {
			return this.showMainDiscipline(id, parentId);
		}

		return this.showSubdiscipline(parentId);
	}

	private showMainDiscipline(id: string, parentId: string | undefined): boolean {
		return this.noDisciplineSelected() || this.mainDisciplineSelected(id, parentId) || this.subDisciplineSelected(id);
	}

	private showSubdiscipline(parentId: string): boolean {
		return (
			(this.existsDisciplineSelected() && parentId === this.selectedDiscipline.value) || parentId === this.selectedParentIdentity.value
		);
	}

	private subDisciplineSelected(id: string): boolean {
		return id === this.selectedParentIdentity.value;
	}

	private mainDisciplineSelected(id: string, parentId: string | undefined): boolean {
		return id === this.selectedDiscipline.value && parentId === undefined;
	}

	private existsDisciplineSelected(): boolean {
		return this.selectedDiscipline.value !== undefined;
	}

	private noDisciplineSelected(): boolean {
		return this.selectedDiscipline.value === undefined;
	}
}
