import { IFilterProvider } from '@/interfaces/filter-providers/IFilterProvider';
import { FilterKey } from '@/models/FilterKey';
import Checkbox from '@/presentation/checkbox/Checkbox.class';

export default class AreaCheckbox extends Checkbox {
	public constructor(
		public readonly optionName: string,
		public readonly optionValue: string,
		public readonly filterKey: FilterKey,
		public readonly filterProvider: IFilterProvider,
		private readonly emitAreaFilterChange: (value: string) => void
	) {
		super(optionName, optionValue, FilterKey.AREA, filterProvider);
	}

	public updateAreaSelection(): void {
		this.updateTackingAction();
		this.emitAreaFilterChange(this.optionValue);
	}
}
