import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { FilterKey } from "../FilterKey";
import { Tag } from "./Tag";

export class KeywordTag extends Tag {
	public constructor(
		public readonly key: FilterKey.KEYWORD_WHAT | FilterKey.KEYWORD_WHERE,
		public readonly values: readonly string[],
		public readonly required: boolean,
		public readonly disabled: boolean,
		public readonly filterProvider: IFilterProvider
	) {
		super(key, values, required, disabled, filterProvider);
	}

	protected getDisplayNameForSingleValue(value: string): string {
		return `"${value}"` || '';
	}

	public async clearFilter(): Promise<void> {
		await this.filterProvider.clearFilter(this.key);
	}
}