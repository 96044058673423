export enum DegreeTypeFilterOptionValue {
	PHD = 'phd',
	DBA = 'dba',
	DOCTORATE = 'doct',
	MSC = 'msc',
	MA = 'ma',
	MBA = 'mba',
	LLM = 'llm',
	MPHIL = 'mphil',
	MLITT = 'mlitt',
	MRES = 'mres',
	MED = 'med',
	MENG = 'meng',
	POSTGRADIP = 'postgraddip',
	POSTGRADCERT = 'postgradcert',
	PREMASTER = 'premaster',
	BSC = 'bsc',
	BA = 'ba',
	LLB = 'llb',
	BENG = 'beng',
	BBA = 'bba',
	ASSOCIATE_DEGREE = 'ad',
	ACADEMY_PROFESSION = 'ap',
	ADVANCED_DIPLOMA = 'adiploma',
	GRADUATE_CERTTIFICATE = 'gcertificate',
	GRADUATE_DIPLOMA = 'gdiploma',
	PREBACHELOR = 'prebachelor',
	CERTIFICATE = 'certificate',
}
