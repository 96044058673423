export enum DurationFilterOptionValue {
	LESS_THAN_ONE_MONTH = '[-1,29]',
	ONE_TO_THREE_MONTHS = '[30,90]',
	MORE_THAN_THREE_MONTHS = '[91,-1]',
	LESS_THAN_ONE_YEAR = '[-1,359]',
	ONE_YEAR = '[360,360]',
	ONE_AND_A_HALF_YEARS = '[540,540]',
	LESS_THAN_TWO_YEARS = '[-1,719]',
	MORE_THAN_TWO_YEARS = '[721,-1]',
	TWO_YEARS = '[720,720]',
	TWO_AND_A_HALF_YEARS = '[900,900]',
	THREE_YEARS = '[1080,1080]',
	THREE_AND_A_HALF_YEARS = '[1260,1260]',
	FOUR_YEARS = '[1440,1440]',
	MORE_THAN_FOUR_YEARS = '[1441,-1]',
}
